import Layout from "@components/layout/layout";
import { Loading } from "@components/loading";
import UnauthErrorComponent from "@components/unauth-error-component/unauth-error-component";
import type { NextPageWithLayout } from "@pages/types";
import { UNAUTH_CTA_URL, UNAUTH_CTA_URL_STG } from "@utils/app-constants";
import { getIdpConfigByOpCo } from "@utils/idp-helpers";
import { useBrand } from "@utils/use-brand";
import type { GetServerSideProps } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import { signIn } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { type ReactElement, useEffect } from "react";
import { Box } from "@infinitaslearning/pixel-design-system";
import { getEnvironment } from "@utils/common";

const Unauthenticated: NextPageWithLayout = () => {
  const { t } = useTranslation("unauth");

  const router = useRouter();
  const hasAutoLoginFailed = router.query?.error === "Callback";
  const callbackUrl = router.query.callbackUrl ? (router.query.callbackUrl as string) : "/";
  const { locale, opCo } = useBrand();

  const isStaging = getEnvironment() === "staging";

  const ctaUrl = isStaging ? UNAUTH_CTA_URL_STG : UNAUTH_CTA_URL;

  useEffect(() => {
    const tryAutoLogin = async () => {
      try {
        const config = await getIdpConfigByOpCo(opCo, locale);
        await signIn(config.id, { callbackUrl }, config.usePrompt ? {} : { prompt: "none" });
      } catch {
        // An error during auto-login will result in a automatic redirect to
        // this page again but with the "?error=Callback" added to the url.
      }
    };

    if (router.isReady && !hasAutoLoginFailed) {
      tryAutoLogin();
    }
  }, [hasAutoLoginFailed, callbackUrl, router.isReady, locale, opCo]);

  return hasAutoLoginFailed ? (
    <Box
      sx={{
        // use full width of parent grid
        gridColumn: "1 / -1",
      }}
    >
      <Head>
        <title>{t("unauthenticated.head-text")}</title>
      </Head>
      <UnauthErrorComponent
        title={t("unauthenticated.head-text")}
        errorMessage={t("unauthenticated.error-message")}
        ctaText={t("unauth.btn-text-basispoort")}
        ctaUrl={ctaUrl}
      />
    </Box>
  ) : (
    <Loading />
  );
};

Unauthenticated.getLayout = function getLayout(page: ReactElement) {
  return <Layout showHeader>{page}</Layout>;
};

export default Unauthenticated;

export const getServerSideProps: GetServerSideProps = async (context) => {
  return {
    props: {
      ...(await serverSideTranslations(context.locale as string, [
        "common",
        "unauth",
        "evaluation",
      ])),
    },
  };
};
